<template>
  <div class="w-full">
    <container ref="input" v-bind="container">
      <div class="-py-2 h-full w-full flex items-center justify-between">
        <div class="h-full flex-grow flex items-center">
          <template v-if="_value">
            <template v-if="isMulti">
              <div class="flex items-center flex-wrap mr-3 mt-1">
                <template v-for="(item, index) in label">
                  <o-chip
                    :key="index"
                    icon="x"
                    size="sm"
                    :variant="value[index].variant || 'info'"
                    flat
                    icon-after
                    class="mr-1 mb-1"
                    @action="remove(index)"
                  >
                    <slot name="value" :item="value[index]" :isMulti="isMulti">
                      <span class="text-sm">{{ item }}</span>
                    </slot>
                  </o-chip>
                </template>

                <input
                  v-if="!isComplete"
                  ref="input"
                  v-model="query"
                  :placeholder="placeholder"
                  type="text"
                  :class="inputClass"
                  @keyup.esc="close"
                  @keyup.up="move(-1)"
                  @keyup.down="move(1)"
                  @keyup.enter="select"
                  @click="handleInputClick"
                >
              </div>
            </template>
            <template v-else>
              <slot name="value" :item="value">
                <p v-if="label">
                  {{ label }}
                </p>
              </slot>
            </template>
          </template>
          <input
            v-if="!isComplete && !isMulti"
            ref="input"
            v-model="query"
            :placeholder="placeholder"
            type="text"
            class="flex-grow bg-transparent ml-1"
            @keyup.esc="close"
            @keyup.up="move(-1)"
            @keyup.down="move(1)"
            @keyup.enter="select"
            @click="handleInputClick"
          >
        </div>
        <o-loader v-if="searching" size="16" />
        <o-button
          v-if="isComplete && !isMulti"
          icon="x"
          size="sm"
          flat
          @click="clearValue"
        />
      </div>
      <template #leading>
        <slot name="leading" />
      </template>

      <template #trailing>
        <slot name="trailing" />
      </template>
    </container>

    <o-dropdown
      v-if="anchor"
      ref="dropdown"
      placement="bottom-start"
      :arrow="false"
      :anchor="anchor"
      default-class="h-full md:h-auto origin-top-right w-full min-w-52 border border-gray-200 bg-white shadow rounded dark:bg-gray-900 dark:border-gray-800"
      padding=""
      class="w-full"
      same-width
    >
      <template #content>
        <div>
          <div class="md:hidden bg-gray-50 border-b flex items-center space-x-2 p-3 dark:bg-gray-900">
            <div class="flex flex-grow p-2 rounded border bg-white focus-within:ring-2 dark:bg-gray-900">
              <input
                v-if="!isComplete"
                ref="mobileQuery"
                :value="query"
                :placeholder="placeholder"
                type="text"
                class="flex-grow bg-transparent"
                @input="evt=>query=evt.target.value"
              >
            </div>
            <o-button flat variant="danger" icon="x" size="sm" @click="handleCloseClick" />
          </div>
          <div class="py-1">
            <div
              v-for="(result, index) in results"
              :key="index"
              :class="result.rowClass || ''"
            >
              <div
                :class="[
                  result.itemClass || 'px-3 py-2 cursor-pointer transition duration-100 east-in-out',
                  focused === index ? 'bg-blue-100 text-blue-700 dark:bg-blue-900 dark:text-blue-300' : 'bg-white dark:bg-gray-900'
                ]"
                @mouseenter="focused = index"
                @click="select"
              >
                <template v-if="result.persist">
                  <p v-html="result.label" />
                </template>
                <template v-else>
                  <slot name="option" :option="result">
                    <p v-html="result.label" />
                  </slot>
                </template>
              </div>
            </div>
            <div v-if="!hasResults && !searching">
              <p class="p-3 opacity-80">
                <template v-if="query">
                  {{ $t('components.fields.typeahead.noResults') }}
                </template>
                <template v-else>
                  {{ $t('components.fields.typeahead.typeToSearch') }}
                </template>
              </p>
            </div>
            <div v-if="searching">
              <p class="p-3 opacity-80">
                {{ $t('components.fields.typeahead.searching') }}
              </p>
            </div>
          </div>
        </div>
      </template>
    </o-dropdown>
  </div>
</template>

<script>
import Fuse from 'fuse.js'
import debounce from 'lodash/debounce'

import Container from './Container'

import classes from '@/mixins/fields/classes.js'
import attributes from '@/mixins/fields/attributes.js'
import methods from '@/mixins/fields/methods.js'
import input from '@/mixins/fields/input.js'

export default {
  components: {
    Container,
  },
  mixins: [attributes, classes, methods, input],
  props: {
    value: {
      type: [Array, Object, String, Boolean, Number],
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    keys: {
      type: Array,
      default: () => [],
    },
    max: {
      type: Number,
      default: 100,
    },
    limit: {
      type: Number,
      default: 10,
    },
    placeholder: {
      type: String,
      default: 'Find',
    },
    persist : {
      type: Array,
      default: () => [],
    },
    filter: {
      type: Function,
      default: null,
    },
    defaultSizeClass: {
      type: String,
      default: 'px-1',
    },
    inputClass: {
      type: String,
      default: 'flex-grow bg-transparent ml-1 mb-1 p-2 focus:bg-gray-200 dark:focus:bg-gray-900 rounded-md leading-none',
    },
    findAllMatches: {
      type: Boolean,
      default: true,
    },
    threshold: {
      type: Number,
      default: 0.6,
    },
  },
  data () {
    return {
      query: '',
      focused: -1,
      anchor: false,
      searching: false,
      results: [],
    }
  },
  computed: {
    _value: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      },
    },
    _keys () {
      if (this.keys.length) {
        return this.keys.map((key) => {
          if (typeof key === 'object') {
            return {
              ...key,
              name: `value.${key.name}`,
            }
          }

          return `value.${key}`
        })
      } 

      return ['value']
    },
    label () {
      const value = this._value

      if (this.isMulti) {
        return this.value.map(item => this.getLabel(item))
      }

      return this.getLabel(value)
    },
    active () {
      return this.$refs.dropdown.show
    },
    isMulti () {
      return this.value && Array.isArray(this.value)
    },
    isComplete () {
      if (this.isMulti) {
        return this._value.length === this.max
      } else {
        return !!this._value
      }
    },
    hasResults () {
      const results = this.results.length
      const persist = this.persist.length

      return results - persist
    },
  },
  watch: {
    query (val) {
      this.$emit('update:query', val)

      if (val !== '' && !this.active) {
        this.$refs.dropdown.open()
        this.searching = true
      }

      if (val === '' && this.active) {
        this.$refs.dropdown.close()
      }

      this.search()
    },
  },
  mounted () {
    this.anchor = this.$refs.input.$el
  },
  methods: {
    remove (index) {
      this._value.splice(index, 1)
    },
    clearValue () {
      this._value = null
    },
    close () {
      this.query = ''
    },
    search: debounce(function () {
      const query = this.query
      const keys = this._keys
      const value = this._value
      const limit = this.limit
      const persist = this.persist
      const filter = this.filter

      const options = this.value
        ? this.options
          .filter((option) => {
            return value &&
              value !== option.value &&
              (!Array.isArray(option.value) || !value.includes(option.value))
          })
        : this.options

      console.log(this._keys)

      const fuse = new Fuse(options, {
        keys,
        findAllMatches: this.findAllMatches,
        threshold: this.threshold,
      })

      let results = fuse
        .search(query, {
          limit,
        })
        .map(({ item }) => {
          return item
        })

      console.log(results)

      if (filter) {
        results = results.filter(filter)
      }

      for (const item of persist) {
        const index = item.index !== undefined ? item.index : results.length
        item.persist = true
        results.splice(index, 0, item)
      }

      this.searching = false
      this.results = results
    }, 500),
    move (val) {
      const results = this.results.length
      const focused = this.focused
      const next = focused + val

      if (next > -1 && next < results) {
        this.focused = next
      }
    },
    select () {
      const isMulti = this.isMulti
      const index = this.focused
      const item = this.results[index]

      if (item) {
        if (isMulti) {
          if (this._value.length !== this.max && !this._value.includes(item.value)) {
            this._value.push(item.value)
          }
        } else {
          this._value = item.value
        }
      }

      this.close()
      this.$refs.dropdown.close()
    },
    getLabel (value) {
      const options = this.options
      const item = options.find(option => option.value === value)

      return item ? item.label : value
    },
    handleInputClick ($event) {
      $event.preventDefault()
      // this.$refs.dropdown.open()

      setTimeout(() => {
        this.$refs.mobileQuery?.focus()
      }, 200)
    },
    handleCloseClick () {
      this.$refs.dropdown.close()
      this.query = ''
    },
  },
}
</script>
